<template>
  <div class="viewContainer">
    <v-container>
      <v-row dense>
        <v-col
          align-self="center"
          xs="12"
          offset-xs="0"
          sm="10"
          offset-sm="1"
          md="8"
          offset-md="2"
          lg="6"
          offset-lg="3"
          xl="4"
          offset-xl="4"
        >
          <div v-if="status404">
            <h1 class="display-4 font-weight-thin text-center my-10" v-html="$t('page404')"/>
          </div>
          <v-card elevation="14" :outlined="false" v-if="page !== null">
            <v-toolbar flat color="transparent" style="position:absolute; width: 100%; z-index: 1">
              <v-spacer></v-spacer>
              <div class="align-center d-flex">
                <v-btn 
                  fab
                  x-small
                  @click="switchDarkTheme"
                >
                  <v-icon v-if="$vuetify.theme.dark">mdi-lightbulb</v-icon>
                  <v-icon v-if="!$vuetify.theme.dark">mdi-lightbulb-on-outline</v-icon>
                </v-btn>
              </div>
            </v-toolbar>
            <v-img
              :height="(page.user.cover == null) ? 76 : 260"
              :src="(page.user.cover == null) ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' : page.user.cover"
            >
            </v-img>
            <v-card elevation="0" tile style="margin-top: -76px" color="transparent">
              <v-row
                align="end"
                class="fill-height"
              >
                <v-col
                  align-self="start"
                  cols="12"
                >
                  <v-avatar
                    class="ml-3"
                    color="grey"
                    size="128"
                  >
                    <v-img :src="page.user.avatar"></v-img>
                  </v-avatar>
                </v-col>
                <v-col class="py-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="title" v-html="page.user.name"></v-list-item-title>
                      <v-list-item-subtitle v-html="page.user.bio"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card>
            <v-divider/>
            <v-container v-if="Array.isArray(page.links) && page.links.length > 0">
              <v-row dense v-for="(link, i) in page.links" :key="i">
                <v-col cols="12">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :color="link.color"
                      :dark="!isBackgroundLight(link.color)"
                      :elevation="hover ? 12 : 2"
                      :href="$init.config.schemeAndHost + '/c/' + link.uuid"
                    >
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="headline" v-html="link.title"/>
                          <v-card-text style="margin-top:-16px" v-html="(link.description !== null) ? link.description.replace(/(?:\r\n|\r|\n)/g, '<br />') : null"/>
                        </div>
                      </div>
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                        </v-overlay>
                      </v-fade-transition>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-overlay
            v-if="loading"
            absolute
          >
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <div v-if="page !== null">
            <v-btn v-if="parseInt(page.user.premium) !== 1" :to="{name: 'home'}" large exact block text color="accent" class="no-caps mt-3"><v-icon class="mr-2" size="15">mdi-heart</v-icon> {{ $init.app.name }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { isBackgroundLight } from '@/utils/helpers';

export default {
  data: () => ({
    loading: true,
    page: null,
    status404: false
  }),
  created () {
    this.loadData()
  },
  methods: {
    isBackgroundLight,
    loadData () {
      this.axios
        .get('/page', { params: { slug: this.$route.params.slug }})
        .then(res => {
          this.page = res.data
          document.title = this.page.user.name
          this.loading = false
        })
        .catch(err => {
          if (err.response.data.status === '404') {
            this.status404 = true
            this.loading = false
          }
        })
    },
    switchDarkTheme () {
      let dark = this.$vuetify.theme.dark
      this.$vuetify.theme.dark = !dark
      this.$store.dispatch('setDark', !dark)
    }
  }
};
</script>
